import * as Sentry from '@sentry/nextjs'
import type { SentryScope } from './SentryLogger'

// TODO: This is a duplicate of SentryLogger.ts that uses @sentry/nextjs instead of @sentry/node
// to enable client-side logging. In the future, we should refactor to have a single logger that
// works in both environments.
export const logToSentryWithLocalScopeClient = async (
  error: Error | unknown,
  localScope: SentryScope,
  captureMessage = ''
) => {
  try {
    console.log(`Logging error to Sentry: \n - Message: ${captureMessage}`)

    Sentry.withScope(function (scope) {
      if (localScope.extra) {
        Object.keys(localScope.extra).forEach((key) =>
          scope.setExtra(key, localScope.extra![key])
        )
      }

      if (localScope.tags) {
        Object.keys(localScope.tags).forEach((key) =>
          scope.setTag(key, localScope.tags![key])
        )
      }

      if (localScope.level) {
        scope.setLevel(localScope.level as Sentry.Severity)
      }

      if (captureMessage) {
        Sentry.captureMessage(captureMessage)
      }

      Sentry.captureException(error)
    })
  } catch (error) {
    console.error(`Failed to log to Sentry with: \n ${error}`)
  }
}
