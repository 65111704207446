import {
  IAssignmentDetails,
  IAssignmentEvent,
  offlineInit,
  IAssignmentLogger,
  Flag,
  ObfuscatedFlag,
} from '@eppo/js-client-sdk'
import { logToSentryWithLocalScopeClient } from './SentryLoggerClient'
import { EppoVariationContainer } from 'types/generated/contentful-types'

/*
 * Default value - used for local development
 * Used to identify the user in Eppo
 */
export const DEFAULT_CLIENT_ID = 'localhost' as const

/*
 * Event name - used to identify the assignment event in the dataLayer
 */
export const EPPO_ASSIGNMENT_EVENT_NAME = 'eppo_assignment_event' as const

/*
 * Control variant - used to identify the control variation in Eppo
 */
export const CONTROL_VARIANT = 'control' as const

// Types
type EppoEventName = typeof EPPO_ASSIGNMENT_EVENT_NAME
export type EppoAssignmentGA4Event = IAssignmentEvent & {
  event: EppoEventName
}

const defaultAssignmentLogger: IAssignmentLogger = {
  logAssignment(assignment) {},
}

export const initializeEppo = async (
  assignmentLogger: IAssignmentLogger = defaultAssignmentLogger
) => {
  try {
    const response = await fetch('/api/eppo-configuration')
    const flagsConfiguration: Record<string, Flag | ObfuscatedFlag> =
      await response.json()

    await offlineInit({
      flagsConfiguration,
      assignmentLogger,
      isObfuscated: false,
    })

    return true
  } catch (error) {
    logToSentryWithLocalScopeClient(
      error,
      {
        tags: { service: 'Eppo Experiment' },
        level: 'error',
      },
      'Failed to initialize Eppo SDK'
    )
    throw error
  }
}

/**
 * Parses the GA client ID from the cookie.
 *
 * @param clientId - The GA client ID - Example: GA1.1.1756478928.1734630350
 * @returns The parsed GA client ID - Example: 1756478928.1734630350
 */
export const parseGaClientId = (clientId: string): string => {
  try {
    const cookieParts = clientId.split('.')
    const cookieClientId = cookieParts.slice(2).join('.')
    return cookieClientId
  } catch (error) {
    logToSentryWithLocalScopeClient(
      error,
      {
        tags: { service: 'Eppo Experiment' },
        level: 'error',
        extra: {
          clientId,
        },
      },
      'Failed to parse GA client ID'
    )
    return clientId
  }
}

/**
 * Creates an assignment event for the given assignment details, experiment, and client ID.
 * Since we need access to the window DOM element to push Eppo assignment events to BigQuery,
 * we build the event object server-side and pass it to the frontend where it can be pushed
 * to the dataLayer.
 *
 * @param assignmentDetails - The assignment details from Eppo SDK.
 * @param experiment - The experiment configuration from Contentful.
 * @param clientId - The GA client ID used to identify the user.
 * @returns The assignment event object that will be passed to the frontend for dataLayer tracking.
 */
export const createAssignmentEventDetail = (
  assignmentDetails: IAssignmentDetails<string>,
  experiment: EppoVariationContainer,
  clientId: string,
  isClientSide = false
): IAssignmentEvent | null => {
  try {
    const { variation, evaluationDetails } = assignmentDetails
    const { matchedAllocation } = evaluationDetails
    return {
      allocation: matchedAllocation?.key || '',
      evaluationDetails,
      experiment: `${experiment?.flagKey}-${matchedAllocation?.key}` || null,
      featureFlag: experiment?.flagKey || '',
      format: isClientSide ? 'CLIENT' : 'SERVER',
      subject: clientId,
      subjectAttributes: {},
      timestamp: evaluationDetails.configFetchedAt,
      variation,
    }
  } catch (error) {
    console.error('Failed to create assignment event:', error)
    return null
  }
}

/**
 * Returns the campaign page ID based on whether to show control or treatment variation
 * @param eppoExperiment - Container with control and treatment variations
 * @param showControl - Flag to determine which variation to use
 * @returns Campaign page ID or null if not found
 */
export const getCampaignPageId = (
  eppoExperiment: EppoVariationContainer,
  showControl: boolean
): string | null => {
  if (showControl) {
    const controlCampaignPageId = eppoExperiment?.controlVariation?.sys?.id
    if (!controlCampaignPageId) {
      console.error('Control variation ID not found...')
      return null
    }
    return controlCampaignPageId
  }

  const treatmentCampaignPageId =
    eppoExperiment?.treatmentVariationsCollection?.items[0]?.sys?.id

  if (!treatmentCampaignPageId) {
    console.error('Treatment variation ID not found...')
    return null
  }

  return treatmentCampaignPageId
}
