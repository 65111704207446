import React, { useEffect, useState, useMemo } from 'react'
import * as EppoSdk from '@eppo/js-client-sdk'
import { datadogRum } from '@datadog/browser-rum'

import PageBlockRenderer, {
  PageBlockCollectionsProps,
} from '../pageBlocks/PageBlockRenderer'
import { logToSentryWithLocalScopeClient } from '@/utils/SentryLoggerClient'
import {
  createAssignmentEventDetail,
  EPPO_ASSIGNMENT_EVENT_NAME,
  EppoAssignmentGA4Event,
  initializeEppo,
  parseGaClientId,
} from '@/utils/EppoHelpers'
import { Entry, EppoVariationContainer } from 'types/generated/contentful-types'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export const getCookie = (name: string): string | null => {
  try {
    if (typeof document === 'undefined') return null
    const cookies = document.cookie.split('; ')
    const cookie = cookies.find((cookie) => cookie.startsWith(`${name}=`))
    return cookie ? cookie.split('=')[1] : null
  } catch (error) {
    console.error('Error getting cookie', error)
    return null
  }
}

function useEppo(
  flagKey: string,
  defaultValue = 'control',
  eppoExperiment: EppoVariationContainer
) {
  const VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || null
  const isProduction = VERCEL_ENV === 'production'
  const isDevelopment = VERCEL_ENV === 'development' || VERCEL_ENV === 'preview'
  const isLocal = !isDevelopment && !isProduction
  const localClientId = 'localhost'

  const [isInitialized, setIsInitialized] = useState(false)
  const [clientId, setClientId] = useState<string | null>(
    isLocal ? localClientId : null
  )
  const [variation, setVariation] = useState<string>(defaultValue)

  useEffect(() => {
    if (!flagKey) return

    const initializeEppoClient = async () => {
      try {
        await initializeEppo()
        setIsInitialized(true)
      } catch (error) {
        logToSentryWithLocalScopeClient(
          error,
          {
            tags: { service: 'Eppo Experiment' },
            level: 'error',
          },
          'Failed to initialize Eppo'
        )
      }
    }

    initializeEppoClient()
  }, [flagKey])

  useEffect(() => {
    if (!flagKey) return
    let attempts = 0
    const maxAttempts = 3

    const getGACookie = () => {
      const gaCookie = getCookie('_ga')

      if (gaCookie) {
        const cookieClientId = parseGaClientId(gaCookie)
        const isValid =
          cookieClientId && cookieClientId !== 'undefined.undefined'

        if (isValid) {
          setClientId(cookieClientId)
          return
        }
      }

      if (attempts < maxAttempts) {
        attempts++
        setTimeout(getGACookie, 500)
      } else {
        // After max attempts, fallback to control
        setVariation('control')
        setIsInitialized(true)

        logToSentryWithLocalScopeClient(
          new Error('Failed to get GA client ID after max attempts'),
          {
            tags: { service: 'Eppo Experiment' },
            level: 'warning',
          },
          'Falling back to control variation'
        )
      }
    }

    getGACookie()
  }, [flagKey])

  // Memoize the assignment payload to prevent unnecessary recalculations
  // Only updates when clientId, flagKey, or defaultValue change
  const assignmentPayload = useMemo(
    () => ({
      flagKey,
      subjectKey: clientId,
      defaultValue: defaultValue || 'control',
    }),
    [clientId, flagKey, defaultValue]
  )

  const getAssignment = useMemo(() => {
    if (!flagKey || !clientId || !isInitialized) return null

    return async () => {
      const client = EppoSdk.getInstance()

      try {
        const assignmentDetails = client.getStringAssignmentDetails(
          assignmentPayload.flagKey,
          clientId,
          {}, // subjectAttributes - Add any additional attributes here
          assignmentPayload.defaultValue
        )

        const event = createAssignmentEventDetail(
          assignmentDetails,
          eppoExperiment,
          clientId,
          true
        )

        if (event) {
          const gaEvent: EppoAssignmentGA4Event = {
            event: EPPO_ASSIGNMENT_EVENT_NAME,
            ...event,
          }

          window.dataLayer.push(gaEvent)

          datadogRum.addFeatureFlagEvaluation(
            gaEvent.featureFlag,
            gaEvent.variation
          )
        }

        // getStringAssignment returns a string like 'treatment-1' or 'control' that does not directly correlate with a Contentful ID.
        // It does (appear) to map to the index of the treatment in the treatmentVariationsCollection. The following string manipulation
        // attempts to find use the correct index to find the correct entry.
        setVariation(assignmentDetails.variation)

        return assignmentDetails.variation ?? assignmentPayload.defaultValue
      } catch (error) {
        logToSentryWithLocalScopeClient(
          error,
          {
            tags: { service: 'Eppo Experiment' },
            level: 'error',
            extra: {
              assignmentPayload,
            },
          },
          'Failed to get Eppo assignment'
        )
        return assignmentPayload.defaultValue
      }
    }
  }, [clientId, isInitialized, assignmentPayload, flagKey])

  // 6. Final useEffect - Runs when getAssignment changes
  useEffect(() => {
    if (!flagKey || !getAssignment) return
    getAssignment().then(setVariation)
  }, [getAssignment, flagKey])

  return {
    variation: flagKey ? variation : null,
    isInitialized: flagKey ? isInitialized : false,
  }
}

export default function EppoExperiment({
  eppoExperiment,
}: {
  eppoExperiment: EppoVariationContainer
}): React.ReactElement | null {
  const CONTROL_VARIANT = 'control'

  const { flagKey, controlVariation, treatmentVariationsCollection } =
    eppoExperiment

  const { variation, isInitialized } = useEppo(
    flagKey as string,
    'control',
    eppoExperiment
  )

  // Early return if:
  //    - Running on server (SSR)
  //    - Eppo isn't initialized yet
  //    - No variation has been assigned
  if (typeof window === 'undefined' || !isInitialized || !variation) {
    return null
  }

  /**
   * Extract treatment variation index from Eppo's response string (e.g. 'treatment-1').
   * Eppo returns a string like 'treatment-1' or 'control' that doesn't directly map to Contentful.
   * We parse the numeric suffix to find the correct treatment in our variations array.
   */
  const variationIndex = variation.split('-')[1] || null
  let treatmentVariation: Entry | null = null

  if (variationIndex !== null) {
    treatmentVariation =
      treatmentVariationsCollection?.items[parseInt(variationIndex) - 1] || null
  }

  const showControl = variation === CONTROL_VARIANT

  return (
    <>
      <PageBlockRenderer
        {...({
          items: showControl ? [controlVariation] : [treatmentVariation],
          total: 1,
          limit: 1,
          skip: 0,
        } as PageBlockCollectionsProps)}
      />
    </>
  )
}
